const baseUrl = window.location.origin;
const API_URL = "https://hub.coderslab.ro/api";

export default {
  API_URL,
  baseUrl,
  googleClientId:
    "256250541407-o13dlkjfut9ac58idj1hm1uqutvqlejk.apps.googleusercontent.com",
  googleClientSecret: "vOgIyonLaMUJx897PWatBmGX",
  sentryDsn: "https://f31b322f47eb40feb4d7011d0c2c63c0@sentry.io/1318642",
  selectedLang: 'en',
};

